import { create } from 'zustand';
import { ShowCategory } from '../generated/api';

interface ICategoriesState {
	categories: Array<ShowCategory>;
	setCategories: (categories: Array<ShowCategory>) => void;
}

export const useCategories = create<ICategoriesState>()((set) => ({
	categories: [],
	setCategories: (categories: Array<ShowCategory>) => set(() => ({ categories })),
}));
