import React from 'react';
import cl from './ButtonAddNewProduct.module.sass';
import cn from 'classnames';
import { ButtonAddNewProductSvgCRM } from '../../../assets/icons';

interface IButtonAddNewProduct {
	openModal?: () => void;
	size?: 'big' | 'small';
}

export const ButtonAddNewProduct = ({ openModal, size = 'small' }: IButtonAddNewProduct) => {
	return (
		<button
			className={cn({ [cl.buttonAdd]: true, [cl.buttonAddBig]: size === 'big' })}
			onClick={openModal}>
			<ButtonAddNewProductSvgCRM />
			<span>Добавить новый товар</span>
		</button>
	);
};
