import React from 'react';
import cl from './Footer.module.sass'
import {LogoBlock} from "../header/logoBlock";
import {IconTelegram} from "../../assets/icons";
export const Footer = () => {
    return (
        <footer className={cl.footer}>
            <div className={cl.wrap}>
                <div className={cl.leftSide}>
                    <LogoBlock isFooter/>
                    <a className={cl.rightSide}
                       href={'https://t.me/yan_future_apps'}

                    >
                        <IconTelegram/>
                        <span className={cl.rightSideText}>@yan_future_apps</span>
                    </a>
                </div>

                <a className={cl.wrapLink} href={'#header'}>
                    <button className={cl.buttonRight}>
                        Наверх
                    </button>
                </a>
            </div>
        </footer>
    );
};

