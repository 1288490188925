import React from 'react';
import cl from './TitleBlock.module.sass'

interface ITitleBlock {
    boldTitle: string
    desc: string
    rightSide?: string
}

export const TitleBlock = (props: ITitleBlock) => {
    return (
        <div className={cl.titleBlock}>
            <div className={cl.leftSide}>
                <span className={cl.boldTitle}>
                    {props.boldTitle}
                </span>
                <span className={cl.desc}>
                    {props.desc}
                </span>
            </div>
            <div className={cl.rightBlock}>
                <span className={cl.rightSide}>
                    {props.rightSide}
                </span>
            </div>
        </div>
    );
};

