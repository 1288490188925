import React from 'react';
import cl from './AdminAdd.module.sass';
import { Configurator } from '../../components/configurator';
import { AccountForm } from './accountForm';
import { AppForm } from './appForm';
import { DesignForm } from './designForm';

export const AdminAdd = () => {
	return <div className={cl.adminAdd}>{/*<Configurator />*/}</div>;
};
