import React from 'react';
import cl from './ButtonMenu.module.sass'
import classNames from "classnames";
import {useIsAbout} from "../../../store/isAboutState";

interface IButtonMenu {
    isViewSelect: boolean,
    toggle: () => void
    fixedButton?: true
}

export const ButtonMenu = (props: IButtonMenu) => {

    const stateIsAbout = useIsAbout(state => state.isAbout)

    return (
        <>
            {
                props.fixedButton ?
                    <div
                        className={classNames({
                            [cl.menu__fixed]: true,
                            [cl.close__menu]: props.isViewSelect,
                            [cl.IsAbout]: stateIsAbout,
                        })}
                        onClick={props.toggle}
                    >
                        <span></span>
                    </div>
                    :
                    <div
                        className={classNames({
                            [cl.menu__btn2]: true,
                            [cl.close__menu]: props.isViewSelect,
                            [cl.IsAbout]: stateIsAbout,
                        })}
                        onClick={props.toggle}
                        // style={{background: '#FF007A'}}
                    >
                        <span></span>
                    </div>
            }

        </>

    );
};

