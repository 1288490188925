export const ParseBackendText = (text: string) => {
	const lexemes = Array<{ type: string; value: string }>();
	let accumulatedString = '';
	let accumulatedBold = '';
	let accumulatedLink = '';

	const appendAccumulatedString = () => {
		lexemes.push({ type: 'normal', value: accumulatedString });
		accumulatedString = '';
	};
	const appendAccumulatedBold = () => {
		lexemes.push({ type: 'bold', value: accumulatedBold });
		accumulatedBold = '';
	};
	const appendAccumulatedLink = () => {
		lexemes.push({ type: 'link', value: accumulatedLink });
		accumulatedLink = '';
	};
	const appendNewLine = () => {
		lexemes.push({ type: 'line-break', value: '' });
	};
	const parseAndAppendBold = (start: number) => {
		let offset = 0;
		for (let j = start; j < text.length; j++) {
			offset += 1;
			if (text[j] === '*' && text[j + 1] === '*') {
				appendAccumulatedBold();
				offset += 2;
				break;
			} else accumulatedBold += text[j];
			if (j === text.length) appendAccumulatedBold();
		}
		return offset;
	};
	const parseAndAppendLink = (start: number) => {
		let offset = -1;
		for (let j = start; j < text.length; j++) {
			offset += 1;
			if (text[j] === ']') {
				appendAccumulatedLink();
				offset += 1;
				break;
			} else accumulatedLink += text[j];

			if (j === text.length) appendAccumulatedLink();
		}
		return offset;
	};

	for (let i = 0; i < text.length; i++) {
		if (text[i] === '*' && text[i + 1] === '*') {
			appendAccumulatedString();
			const offset = parseAndAppendBold(i + 2);
			i += offset;
		} else if (text[i] === '[') {
			appendAccumulatedString();
			const offset = parseAndAppendLink(i + 1);
			i += offset;
		} else if (text[i] === '\\' && text[i + 1] === 'n') {
			appendAccumulatedString();
			appendNewLine();
			i += 1;
		} else accumulatedString += text[i];

		if (i === text.length) appendAccumulatedString();
	}

	return lexemes;
};
