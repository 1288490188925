import React from 'react';
import cl from './About.module.sass'
import {BlackPart} from "./blackPart";
import {NewTeam} from "./newTeam";
import {OurProducts} from "./ourProducts";
import {BottomText} from "./bottomText";
import {Portfolio} from "./portfolio";

export const About = () => {
    return (
        <div className={cl.about}>
            <BlackPart/>

            <div className={cl.another}>
                <Portfolio/>
                <NewTeam/>
                <OurProducts/>
                <BottomText/>
            </div>
        </div>
    );
};

