import React from 'react';
import cl from './Navbar.module.sass';
import { ILinkBlock, LinkBlock } from './linkBlock';
import { IconAbout, IconApps, IconGaranty, IconStore } from '../../../assets/icons';

const listLinks = [
	{ link: '/', namePage: 'Магазин софта', icon: <IconStore /> },
	{ link: '/apps', namePage: 'Приложения', icon: <IconApps /> },
	{ link: '/about', namePage: 'О нас', icon: <IconAbout /> },
];

export const Navbar = () => {
	return (
		<nav className={cl.nav}>
			{listLinks.map((elem, index) => (
				<LinkBlock
					link={elem.link}
					namePage={elem.namePage}
					icon={elem.icon}
					key={index}
					toggle={() => {}}
				/>
			))}
		</nav>
	);
};
