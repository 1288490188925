import React, { useCallback } from 'react';
import cl from './EditDesignCard.module.sass';
import { OrderButton } from '../../pages/store/products/orderButton';
import { Link } from 'react-router-dom';
import { EditCardSvg, IconDeleteCRM } from '../../assets/icons';
import { ParseBackendText } from '../../utils/parse-backend-text';

export interface IDesignCard {
	img: string;
	nameDesign: string;
	description: string;
	price: number;
	editClick: () => void;
	deleteClick: () => void;
}

export const EditDesignCard = (props: IDesignCard) => {
	const parseLink = useCallback((description: string) => {
		const lexemes = ParseBackendText(description);
		return lexemes.map((x, i) => {
			if (x.type === 'normal') {
				return <span className={cl.normal}>{x.value}</span>;
			}
			if (x.type === 'bold') {
				return <span className={cl.bold}>{x.value}</span>;
			}
			if (x.type === 'link') {
				return (
					<a
						className={cl.link}
						href={x.value.split('|')[0]}
						rel="noreferrer"
						target="_blank">
						{x.value.split('|')[1]}
					</a>
				);
			}
			if (x.type === 'line-break') {
				return <br />;
			}
			return '';
		});
	}, []);

	return (
		<div className={cl.designCard}>
			<div className={cl.wrap}>
				<img src={props.img} alt={'Картинка дизайна'} />
				<div className={cl.blockText}>
					<div className={cl.middleBlock}>
						<span className={cl.nameDesign}>{props.nameDesign}</span>
					</div>
					<div className={cl.description}>{parseLink(props.description)}</div>
				</div>
			</div>
			<div className={cl.wrapButton}>
				<div className={cl.editButton}>
					<button onClick={props.editClick}>
						<EditCardSvg />
					</button>
					<button onClick={props.deleteClick}>
						<IconDeleteCRM />
					</button>
				</div>
				<OrderButton>Купить ({[props.price]}$)</OrderButton>
			</div>
		</div>
	);
};
