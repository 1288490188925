import React, { useEffect } from 'react';
import cl from './AdminStore.module.sass';
import { Category } from './category';
import { CategoryWithItems } from './CategoryWithItems';
import { useCategories } from '../../store/categoriesState';
import { CategoryService } from '../../generated/api';

export const AdminStore = () => {
	const { categories, setCategories } = useCategories();

	useEffect(() => {
		CategoryService.getAllCategoriesCategoryAllGet().then((response) =>
			setCategories(response),
		);
	}, [setCategories]);

	return (
		<div className={cl.adminStore}>
			<Category />
			<div className={cl.mainContent}>
				{categories.map((cat) => (
					<CategoryWithItems
						categoryType={cat.type_id}
						key={cat.id}
						title={cat.name}
						categoryId={cat.id}
					/>
				))}
			</div>
		</div>
	);
};
