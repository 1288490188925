import React, {ReactNode} from 'react';
import cl from './Platform.module.sass'


interface IPlatform {
    icon: ReactNode
    namePlatform: string
}

export const Platform = (props: IPlatform) => {
    return (
        <div className={cl.platform}>
            {props.icon}
            <span className={cl.name}>{props.namePlatform}</span>
        </div>
    );
};

