/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddCategory } from '../models/AddCategory';
import type { ShowCategory } from '../models/ShowCategory';
import type { UpdateCategoriesPosition } from '../models/UpdateCategoriesPosition';
import type { UpdateCategoryResponse } from '../models/UpdateCategoryResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CategoryService {
    /**
     * Update Categories Positions
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static updateCategoriesPositionsCategoryPositionsPatch(
        requestBody: UpdateCategoriesPosition,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/category/positions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Categories
     * @returns ShowCategory Successful Response
     * @throws ApiError
     */
    public static getAllCategoriesCategoryAllGet(): CancelablePromise<Array<ShowCategory>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/category/all',
        });
    }
    /**
     * Get Category By Id
     * @param categoryId
     * @returns ShowCategory Successful Response
     * @throws ApiError
     */
    public static getCategoryByIdCategoryGet(
        categoryId: number,
    ): CancelablePromise<ShowCategory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/category',
            query: {
                'category_id': categoryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Category
     * @param requestBody
     * @returns ShowCategory Successful Response
     * @throws ApiError
     */
    public static addCategoryCategoryPost(
        requestBody: AddCategory,
    ): CancelablePromise<ShowCategory> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/category',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Category
     * @param categoryId
     * @returns UpdateCategoryResponse Successful Response
     * @throws ApiError
     */
    public static deleteCategoryCategoryDelete(
        categoryId: number,
    ): CancelablePromise<UpdateCategoryResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/category',
            query: {
                'category_id': categoryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Category By Type
     * @param typeId
     * @returns ShowCategory Successful Response
     * @throws ApiError
     */
    public static getCategoryByTypeCategoryByTypeGet(
        typeId: number,
    ): CancelablePromise<Array<ShowCategory>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/category/by_type',
            query: {
                'type_id': typeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
