import React, { useState, useEffect } from 'react';
import cl from './Configurator.module.sass';
import { CustomSelect } from '../customSelect';
import { AccountForm } from '../../pages/adminAdd/accountForm';
import prev from '../../assets/preview.png';
import { CategoryService, ShowAccount, ShowCategory, ShowDesign } from '../../generated/api';
import { DesignForm } from '../../pages/adminAdd/designForm';

export enum ConfiguratorState {
	New,
	Edit,
}

type ConfiguratorProps = {
	category: ShowCategory;
	state: ConfiguratorState;
	onSuccess: () => void;
	product?: ShowAccount | ShowDesign;
};

export const Configurator = ({ category, state, product, onSuccess }: ConfiguratorProps) => {
	return (
		<div className={cl.wrapper}>
			<div className={cl.leftSide}>
				<span className={cl.title}>Конфигуратор</span>
				{category.type_id === 1 ? (
					<AccountForm
						category={category}
						product={product}
						status={state}
						onSuccess={onSuccess}
					/>
				) : (
					<DesignForm
						category={category}
						product={product}
						status={state}
						onSuccess={onSuccess}
					/>
				)}
			</div>
			{/*
			<div className={cl.rightSide}>
				<div className={cl.catProduct}>
					<span className={cl.catProductTitle}>Категория товара</span>
					<CustomSelect
						options={categoryList.map((x) => x.name)}
						onChange={(event) => {
							setCategory(event.target.value);
						}}
					/>
				</div>
                    */}

			{/*Думаю, превью стоит выделить в отдельный компонент*/}

			{/*<div className={cl.previewBlock}>
					<span className={cl.titlePreview}>Preview</span>
					<div className={cl.preview}>
						<img src={prev} alt={''} className={cl.prevImg} />
						<button className={cl.prevButton}>Купить ($)</button>
					</div>
                    </div>**/}
		</div>
	);
};
