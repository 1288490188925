import React, { useEffect } from 'react';
import cl from './Apps.module.sass';
import { MainWrapperForPage } from '../../components/mainWrapperForPage';
import { TitleBlock } from '../../components/titleBlock';
import { CardApp, ICardApp } from '../../components/cardApp';
import imgApp from '../../assets/imgApp.png';
import { useProducts } from '../../store/productsState';
import { AppService } from '../../generated/api';

const listCardsApp = [
	{
		category: 'Слоты',
		titleApp: 'Luna Vegas Slots - Casino Game',
		imgApp: imgApp,
		descCard:
			'Захватывающее приложение для любителей азартных игр и слот-машин. ' +
			'Устройтесь поудобнее и погрузитесь в мир волнительных спинов и больших выигрышей с SlotMania.',
		price: '799',
	},
	{
		category: 'Слоты',
		titleApp: 'Luna Vegas Slots - Casino Game',
		imgApp: imgApp,
		descCard:
			'Захватывающее приложение для любителей азартных игр и слот-машин. ' +
			'Устройтесь поудобнее и погрузитесь в мир волнительных спинов и больших выигрышей с SlotMania.',
		price: '799',
	},
	{
		category: 'Слоты',
		titleApp: 'Luna Vegas Slots - Casino Game',
		imgApp: imgApp,
		descCard:
			'Захватывающее приложение для любителей азартных игр и слот-машин. ' +
			'Устройтесь поудобнее и погрузитесь в мир волнительных спинов и больших выигрышей с SlotMania.',
		price: '799',
	},
	{
		category: 'Слоты',
		titleApp: 'Luna Vegas Slots - Casino Game',
		imgApp: imgApp,
		descCard:
			'Захватывающее приложение для любителей азартных игр и слот-машин. ' +
			'Устройтесь поудобнее и погрузитесь в мир волнительных спинов и больших выигрышей с SlotMania.',
		price: '799',
	},
];

export const Apps = () => {
	const { apps, setApps } = useProducts();

	const citate =
		'«Лить гемблу без приложения - все равно, что подмигивать девушке в полной темноте»';

	useEffect(() => {
		AppService.getAllAppsAppAllGet().then((response) => setApps(response));
	}, [setApps]);

	return (
		<MainWrapperForPage>
			<TitleBlock
				boldTitle={'Приложения под ключ'}
				desc={
					'Закажите готовую платформу, это выгодно, быстро и качественно, а весь процесс мы возьмем на себя.'
				}
				rightSide={''}
			/>
			{apps.map((elem, index) => (
				<CardApp
					category={elem.app_type}
					titleApp={elem.name}
					imgApp={elem.screenshot_link}
					descCard={elem.description}
					price={elem.price.toString()}
					key={index}
				/>
			))}
		</MainWrapperForPage>
	);
};
