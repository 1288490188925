import React, {useEffect} from 'react';
import cl from "./Layout.module.sass";
import {Outlet} from "react-router-dom";
import {Header} from "../header";
import {Footer} from "../footer";
import {useIsAbout} from "../../store/isAboutState";
import cn from 'classnames'

interface ILayout {
    isAbout: boolean
}

export const Layout = (props: ILayout) => {

    const trueIsAbout = useIsAbout(state => state.trueIsAbout)
    const falseIsAbout = useIsAbout(state => state.falseIsAbout)

    useEffect(() => {
        if (props.isAbout) {
            trueIsAbout()
        } else {
            falseIsAbout()
        }
    })


    return (
        <div className={cl.app}>
            <Header/>
            <div className={cn({
                [cl.mainWrapp]: true,
                [cl.mainWrappIsAbout]: props.isAbout
            }) }>
                <Outlet/>
            </div>
            <Footer/>
        </div>
    );
};

