import cl from './CustomSelect.module.sass';
import React, { InputHTMLAttributes, useState } from 'react';
import classNames from 'classnames';
import { ArrowSelect } from '../../assets/icons';
interface Props extends InputHTMLAttributes<HTMLSelectElement> {
	options: Array<string>;
}

export const CustomSelect = ({ options, name, onChange }: Props) => {
	const [isViewSelect, setIsViewSelect] = useState(false);

	function toggle() {
		setIsViewSelect(!isViewSelect);
	}

	return (
		<div className={cl.wrapperCustomSelect} onClick={toggle}>
			<select className={cl.customSelect} name={name} onChange={onChange}>
				<option className={cl.test} value={''} disabled selected>
					Тип товара
				</option>
				{options.map((option) => (
					<option value={option}>{option}</option>
				))}
			</select>
			<div
				className={classNames({
					[cl.arrowSelect]: true,
					[cl.open]: isViewSelect,
					[cl.close]: !isViewSelect,
				})}>
				<ArrowSelect />
			</div>
		</div>
	);
};
