import React, { useState } from 'react';
import cl from './Header.module.sass';
import { LogoBlock } from './logoBlock';
import { Navbar } from './navbar';
import {
	IconAbout,
	IconApps,
	IconClose,
	IconGaranty,
	IconStore,
	IconTelegram,
} from '../../assets/icons';
import { ILinkBlock, LinkBlock } from './navbar/linkBlock';
import classNames from 'classnames';
import { ButtonMenu } from './buttonMenu';
import { useIsAbout } from '../../store/isAboutState';

const listLinks = [
	{ link: '/', namePage: 'Магазин софта', icon: <IconStore /> },
	{ link: '/apps', namePage: 'Приложения', icon: <IconApps /> },
	{ link: '/about', namePage: 'О нас', icon: <IconAbout /> },
];

export const Header = () => {
	const [isViewSelect, setIsViewSelect] = useState(false);
	const toggle = () => setIsViewSelect(!isViewSelect);
	const stateIsAbout = useIsAbout((state) => state.isAbout);

	return (
		<nav
			className={classNames({
				[cl.header]: true,
				[cl.aboutHeader]: stateIsAbout,
			})}
			id={'header'}>
			<ButtonMenu isViewSelect={isViewSelect} toggle={toggle} fixedButton />
			<div className={cl.wrap}>
				<div className={cl.leftSide}>
					<LogoBlock />
					<Navbar />
				</div>

				<a
					className={classNames({
						[cl.rightSide]: true,
						[cl.rightSideIsAbout]: stateIsAbout,
					})}
					href={'https://t.me/yan_future_apps'}>
					<IconTelegram />
					<span
						className={classNames({
							[cl.rightSideText]: true,
							[cl.rightSideTextIsAbout]: stateIsAbout,
						})}>
						@yan_future_apps
					</span>
				</a>

				<div className={cl.hamburgerMenu}>
					<ButtonMenu isViewSelect={isViewSelect} toggle={toggle} />
					<ul
						className={classNames({
							[cl.menu__box]: true,
							[cl.open]: isViewSelect,
							[cl.close]: !isViewSelect,
						})}>
						{listLinks.map((elem, index) => (
							<LinkBlock
								link={elem.link}
								namePage={elem.namePage}
								icon={elem.icon}
								key={index}
								toggle={toggle}
							/>
						))}

						<div className={cl.bottomBlock}>
							<div className={cl.linkToManager}>
								<span className={cl.title}>Связаться:</span>
								<a
									className={cl.telegramSide}
									href={'https://t.me/yan_future_apps'}>
									<IconTelegram />
									<span className={cl.telegramSideText}>@yan_future_apps</span>
								</a>
							</div>

							<button className={cl.closeButton} onClick={toggle}>
								<IconClose />
								<span>Закрыть</span>
							</button>
						</div>
					</ul>
				</div>
			</div>
		</nav>
	);
};
