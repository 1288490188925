import React from 'react';
import cl from './BlockProduct.module.sass';
import { BlockTitle } from '../../../../components/blockTitle';
import { CustomHR } from '../../../../components/customHR';
import { CardProduct, ICardProduct } from '../../../../components/cardProduct';
import { ShowAccount, ShowDesign } from '../../../../generated/api';

export interface IBlockProduct {
	title: string;
	iconUrl: string;
	listData: Array<ShowAccount>;
}

export const BlockProduct = (props: IBlockProduct) => {
	return (
		<div className={cl.blockProduct}>
			<BlockTitle title={props.title} iconUrl={props.iconUrl} />
			<div className={cl.listCards}>
				{props.listData.map((elem, index) => (
					<CardProduct
						id={elem.id}
						img={elem.icon_link}
						nameApp={elem.name}
						description={elem.description}
						price={elem.price.toString()}
					/>
				))}
			</div>
		</div>
	);
};
