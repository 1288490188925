/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatedOrder } from '../models/CreatedOrder';
import type { CreateOrder } from '../models/CreateOrder';
import type { PaidOrderAccount } from '../models/PaidOrderAccount';
import type { PaidOrderDesign } from '../models/PaidOrderDesign';
import type { ShowOrder } from '../models/ShowOrder';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OrderService {
    /**
     * Create Order
     * @param requestBody
     * @returns CreatedOrder Successful Response
     * @throws ApiError
     */
    public static createOrderOrderPost(
        requestBody: CreateOrder,
    ): CancelablePromise<CreatedOrder> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Order
     * @param orderId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getOrderOrderGet(
        orderId: string,
    ): CancelablePromise<(PaidOrderAccount | PaidOrderDesign | ShowOrder)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order',
            query: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
