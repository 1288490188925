import React, { useCallback, useState } from 'react';
import cl from './DesignCard.module.sass';
import { OrderButton } from '../../pages/store/products/orderButton';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { Form, Formik } from 'formik';
import { OrderService } from '../../generated/api';
import { BlockInput } from '../blockInput';
import { ParseBackendText } from '../../utils/parse-backend-text';

export interface IDesignCard {
	id: string;
	img: string;
	price: number;
	nameDesign: string;
	description: string;
}

export const DesignCard = (props: IDesignCard) => {
	const [openModal, setOpenModal] = useState(false);
	const [isInfoCard, setInfoCard] = useState(false);
	const [orderStatus, setOrderStatus] = useState<'none' | 'creating' | 'pending'>('none');

	const initValues = {
		email: '',
	};

	const parseLink = useCallback(
		(description: string) => {
			const lexemes = ParseBackendText(description);
			return lexemes.map((x, i) => {
				if (x.type === 'normal') {
					return <span className={cl.normal}>{x.value}</span>;
				}
				if (x.type === 'bold') {
					return <span className={cl.bold}>{x.value}</span>;
				}
				if (x.type === 'link') {
					if (x.value.includes('|')) {
						return (
							<a
								className={cl.link}
								href={x.value.split('|')[0]}
								rel="noreferrer"
								target="_blank">
								{x.value.split('|')[1]}
							</a>
						);
					}
					if (x.value === 'INFO') {
						if (!isInfoCard) setInfoCard(true);
						return '';
					}
				}
				if (x.type === 'line-break') {
					return <br />;
				}
				return '';
			});
		},
		[isInfoCard],
	);

	return (
		<div className={cl.designCard}>
			<div className={cl.wrap}>
				<img src={props.img} alt={'Картинка дизайна'} />
				<div className={cl.middleBlock}>
					<span className={cl.nameDesign}>{props.nameDesign}</span>
				</div>
			</div>
			<div className={cl.description}>{parseLink(props.description)}</div>
			<OrderButton disabled={isInfoCard} onClick={() => setOpenModal(true)}>
				Купить ({props.price}$)
			</OrderButton>
			<Modal
				isOpen={openModal}
				overlayClassName={cl.overlay}
				className={cl.content}
				onRequestClose={() => setOpenModal(false)}>
				<div className={cl.wrapper}>
					<div className={cl.wrapperInner}>
						<Formik
							initialValues={initValues}
							onSubmit={(values, formikHelpers) => {
								OrderService.createOrderOrderPost({
									client_email: values.email,
									product_id: props.id,
								})
									.then((response) => {
										setOrderStatus('pending');
										window.location.href = response.payment_link;
									})
									.catch((error) => {
										setOrderStatus('none');
										alert('Произошла ошибка при создании заказа!');
									});
								setOrderStatus('creating');
							}}>
							{({ values, handleChange }) => (
								<>
									{orderStatus === 'none' && (
										<Form className={cl.formGroup}>
											<BlockInput
												title={'Введите свой email'}
												name={'email'}
												value={values.email}
												white={true}
												onChange={handleChange}
												placeholder={'example@example.com'}
											/>
											<button type="submit" className={cl.buy}>
												Приобрести товар
											</button>
										</Form>
									)}
									{orderStatus === 'creating' && (
										<span>Создание заказа в процессе...</span>
									)}
									{orderStatus === 'pending' && (
										<span>
											Заказ создан, сейчас вы будете перенаправлены на сайт
											для оплаты.
										</span>
									)}
								</>
							)}
						</Formik>
					</div>
				</div>
			</Modal>
		</div>
	);
};
