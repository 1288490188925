import React, { useCallback } from 'react';
import cl from './EditCardProduct.module.sass';

import { EditCardSvg, IconDeleteCRM, Minus, Plus } from '../../assets/icons';
import { OrderButton } from '../../pages/store/products/orderButton';
import { DescText } from '../cardProduct/descText';
import { ParseBackendText } from '../../utils/parse-backend-text';

export interface ICardProduct {
	img: string;
	nameApp: string;
	description: string;
	price: number;
	editClick: () => void;
	deleteClick: () => void;
}

export const EditCardProduct = (props: ICardProduct) => {
	const parseDescription = useCallback((description: string) => {
		const lexemes = ParseBackendText(description);
		return lexemes.map((x, i) => {
			if (x.type === 'normal') {
				return <span className={cl.normal}>{x.value}</span>;
			}
			if (x.type === 'bold') {
				return <span className={cl.bold}>{x.value}</span>;
			}
			if (x.type === 'line-break') {
				return <br />;
			}
			return '';
		});
	}, []);

	return (
		<div className={cl.cardProduct}>
			<div className={cl.topBlock}>
				<img src={props.img} alt={'Иконка'} className={cl.imgCard} />
				<div className={cl.wrapEdit}>
					<button onClick={props.editClick}>
						<EditCardSvg />
					</button>
					<button onClick={props.deleteClick}>
						<IconDeleteCRM />
					</button>
				</div>
			</div>
			<div className={cl.middleBlock}>
				<span className={cl.nameApp}>{props.nameApp}</span>
				<div className={cl.descList}>{parseDescription(props.description)}</div>
			</div>
			<OrderButton>Купить ({props.price} $)</OrderButton>
		</div>
	);
};
