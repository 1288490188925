import React, { ReactElement, ReactNode, useEffect } from 'react';
import cl from './App.module.sass';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from './components/layout';
import { Store } from './pages/store';
import { Apps } from './pages/apps';
import { About } from './pages/about';
import { AuthAdmin } from './pages/authAdmin';
import { LayoutAdmin } from './components/layoutAdmin';
import { AdminStore } from './pages/adminStore';
import { AdminApps } from './pages/adminApps';
import { AdminAdd } from './pages/adminAdd';
import { useAuth } from './store/authStore';

function App() {
	const { isAuthorized } = useAuth();

	const guarded = (node: ReactElement) => {
		if (isAuthorized) return node;
		return <Navigate to={'/admin'} />;
	};
	return (
		<>
			<Routes>
				<Route element={<Layout isAbout={false} />}>
					<Route path={'/'} element={<Store />} />
					<Route path={'/order/:id'} element={<Store />} />
					<Route path={'/apps'} element={<Apps />} />
					<Route path={'/admin'} element={<AuthAdmin />} />
					<Route path="*" element={<Store />} />
				</Route>
				<Route element={<Layout isAbout={true} />}>
					<Route path={'/about'} element={<About />} />
				</Route>
				<Route element={<LayoutAdmin isAbout={false} />}>
					<Route path={'/adminStore'} element={guarded(<AdminStore />)} />
					<Route path={'/adminApps'} element={guarded(<AdminApps />)} />
					<Route path={'/adminAdd'} element={guarded(<AdminAdd />)} />
				</Route>
				<Route element={<LayoutAdmin isAbout={true} />}>
					<Route path={'/adminAbout'} element={guarded(<span>админ about</span>)} />
				</Route>
			</Routes>
		</>
	);
}

export default App;
