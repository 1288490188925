import React, {ReactNode} from 'react';
import cl from './MainWrapperForPage.module.sass'

interface IMainWrapperForPage {
    children: ReactNode
}

export const MainWrapperForPage = (props: IMainWrapperForPage) => {
    return (
        <div className={cl.mainWrapperForPage}>
            {props.children}
        </div>
    );
};

