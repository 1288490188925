import React from 'react';
import cl from './BottomText.module.sass'
import imgBottom from '../../../assets/bottomText.png'
import {IconMain, IconStore} from "../../../assets/icons";

export const BottomText = () => {
    return (
        <div className={cl.bottomText}>
            <img src={imgBottom} alt={'Нижнее фото'} className={cl.bottomImg}/>
            <span className={cl.text}>Начни работать с нами уже сегодня!</span>
            <button className={cl.buttonBottom}>
                <IconStore/>
                <span>Перейти в магазин</span>
            </button>
        </div>
    );
};

