import React, { useState } from 'react';
import { WrapMainContent } from '../../components/wrapMainContent';
import imgApp from '../../assets/imgApp.png';
import { CardApp, ICardApp } from '../../components/cardApp';
import { EditCardApp } from '../../components/editCardApp';
import cl from './Admin.module.sass';
import { ButtonAddNewProduct } from '../adminStore/buttonAddNewProduct';
import Modal from 'react-modal';
import { Configurator } from '../../components/configurator';

const listCardsApp = [
	{
		category: 'Слоты',
		titleApp: 'Luna Vegas Slots - Casino Game',
		imgApp: imgApp,
		descCard:
			'Захватывающее приложение для любителей азартных игр и слот-машин. ' +
			'Устройтесь поудобнее и погрузитесь в мир волнительных спинов и больших выигрышей с SlotMania.',
		price: '799',
	},
	{
		category: 'Слоты',
		titleApp: 'Luna Vegas Slots - Casino Game',
		imgApp: imgApp,
		descCard:
			'Захватывающее приложение для любителей азартных игр и слот-машин. ' +
			'Устройтесь поудобнее и погрузитесь в мир волнительных спинов и больших выигрышей с SlotMania.',
		price: '799',
	},
	{
		category: 'Слоты',
		titleApp: 'Luna Vegas Slots - Casino Game',
		imgApp: imgApp,
		descCard:
			'Захватывающее приложение для любителей азартных игр и слот-машин. ' +
			'Устройтесь поудобнее и погрузитесь в мир волнительных спинов и больших выигрышей с SlotMania.',
		price: '799',
	},
	{
		category: 'Слоты',
		titleApp: 'Luna Vegas Slots - Casino Game',
		imgApp: imgApp,
		descCard:
			'Захватывающее приложение для любителей азартных игр и слот-машин. ' +
			'Устройтесь поудобнее и погрузитесь в мир волнительных спинов и больших выигрышей с SlotMania.',
		price: '799',
	},
];

export const AdminApps = () => {
	const [openModal, setOpenModal] = useState(false);
	return (
		<WrapMainContent title={'Приложения в наличии :'}>
			<div className={cl.listApps}>
				{listCardsApp.map((elem: ICardApp, index) => (
					<EditCardApp
						category={elem.category}
						titleApp={elem.titleApp}
						imgApp={elem.imgApp}
						descCard={elem.descCard}
						price={elem.price}
						key={index}
					/>
				))}
				<ButtonAddNewProduct openModal={() => setOpenModal(true)} />
				<Modal
					isOpen={openModal}
					overlayClassName={cl.overlay}
					onRequestClose={() => setOpenModal(false)}>
					{/*<Configurator />*/}
				</Modal>
			</div>
		</WrapMainContent>
	);
};
