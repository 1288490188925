import React, {ReactNode} from 'react';
import cl from './BlockService.module.sass'
interface IBlockService {
    title: string
    icon: ReactNode
}

export const BlockService = (props: IBlockService) => {
    return (
        <div className={cl.blockService}>
            {props.icon}
            <span className={cl.title}>{props.title}</span>
        </div>
    );
};

