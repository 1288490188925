import React, { ReactNode } from 'react';
import cl from './LinkBlock.module.sass';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { useIsAbout } from '../../../../store/isAboutState';
import classNames from 'classnames';

export interface ILinkBlock {
	link: string;
	namePage: string;
	icon: ReactNode;
	toggle: () => void;
}

export const LinkBlock = (props: ILinkBlock) => {
	const isAbout = useIsAbout((state) => state.isAbout);
	return (
		<NavLink
			to={props.link}
			onClick={props.toggle}
			className={({ isActive }) =>
				cn({
					[cl.linkBlock]: true,
					[cl.linkBlockActive]: isActive,
					[cl.linkBlockIsAbout]: isAbout,
					[cl.linkBlockIsAboutActive]: isActive && isAbout,
				})
			}>
			<div
				className={classNames({
					[cl.stroke]: props.namePage === 'Гарант сервис',
				})}>
				{props.icon}
			</div>

			<span
				className={cn({
					[cl.namePage]: true,
					[cl.namePageIsAbout]: isAbout,
				})}>
				{props.namePage}
			</span>
		</NavLink>
	);
};
