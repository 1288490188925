import { useEffect, useState } from 'react';
import cl from './Products.module.sass';
import { BlockProduct } from './blockProduct';
import iconCard from '../../../../src/assets/playMarket.png';
import appStore from '../../../../src/assets/appStore.png';
import design1 from '../../../../src/assets/design1.png';
import design2 from '../../../../src/assets/design2.png';
import { BlockDesignProduct } from './blockDesignProduct';
import { AccountService, DesignService, ShowAccount, ShowDesign } from '../../../generated/api';
import { useProducts } from '../../../store/productsState';
import { useCategories } from '../../../store/categoriesState';

const listProduct = [
	{
		title: 'Самое важное',
		listData: [
			{
				img: iconCard,
				nameApp: 'Google Play Console',
				countApp: '3',
				geo: 'Испания',
				antiDetect: 'AdsPower',
			},
			{
				img: iconCard,
				nameApp: 'Google Play Console',
				countApp: '3',
				geo: 'Испания',
				antiDetect: 'AdsPower',
			},
			{
				img: iconCard,
				nameApp: 'Google Play Console',
				countApp: '3',
				geo: 'Испания',
				antiDetect: 'AdsPower',
			},
			{
				img: iconCard,
				nameApp: 'Google Play Console',
				countApp: '3',
				geo: 'Испания',
				antiDetect: 'AdsPower',
			},
			{
				img: iconCard,
				nameApp: 'Google Play Console',
				countApp: '3',
				geo: 'Испания',
				antiDetect: 'AdsPower',
			},
			{
				img: iconCard,
				nameApp: 'Google Play Console',
				countApp: '3',
				geo: 'Испания',
				antiDetect: 'AdsPower',
			},
			{
				img: iconCard,
				nameApp: 'Google Play Console',
				countApp: '3',
				geo: 'Испания',
				antiDetect: 'AdsPower',
			},
			{
				img: iconCard,
				nameApp: 'Google Play Console',
				countApp: '3',
				geo: 'Испания',
				antiDetect: 'AdsPower',
			},
		],
	},

	{
		title: 'Аккаунты AppStore',
		listData: [
			{
				img: appStore,
				nameApp: 'Аккаунт разработчика Apple Турция',
				countApp: '3',
				geo: 'Испания',
				antiDetect: 'AdsPower',
			},
			{
				img: appStore,
				nameApp: 'Аккаунт разработчика Apple Турция',
				countApp: '3',
				geo: 'Испания',
				antiDetect: 'AdsPower',
			},
			{
				img: appStore,
				nameApp: 'Аккаунт разработчика Apple Турция',
				countApp: '3',
				geo: 'Испания',
				antiDetect: 'AdsPower',
			},
			{
				img: appStore,
				nameApp: 'Аккаунт разработчика Apple Турция',
				countApp: '3',
				geo: 'Испания',
				antiDetect: 'AdsPower',
			},
			{
				img: appStore,
				nameApp: 'Аккаунт разработчика Apple Турция',
				countApp: '3',
				geo: 'Испания',
				antiDetect: 'AdsPower',
			},
			{
				img: appStore,
				nameApp: 'Аккаунт разработчика Apple Турция',
				countApp: '3',
				geo: 'Испания',
				antiDetect: 'AdsPower',
			},
			{
				img: appStore,
				nameApp: 'Аккаунт разработчика Apple Турция',
				countApp: '3',
				geo: 'Испания',
				antiDetect: 'AdsPower',
			},
			{
				img: appStore,
				nameApp: 'Аккаунт разработчика Apple Турция',
				countApp: '3',
				geo: 'Испания',
				antiDetect: 'AdsPower',
			},
		],
	},
];
const listDesignProduct = [
	{
		title: 'Готовый дизайн',
		listData: [
			{ img: design1, nameDesign: 'Дизайн платформы Candy Slots' },
			{ img: design2, nameDesign: 'Дизайн платформы Star Gold' },
			{ img: design1, nameDesign: 'Дизайн платформы Candy Slots' },
			{ img: design2, nameDesign: 'Дизайн платформы Star Gold' },
		],
	},
];

type FilteredAccountsCategory = {
	name: string;
	position: number;
	iconUrl: string;
	accounts: Array<ShowAccount>;
};

type FilteredDesignsCategory = {
	name: string;
	position: number;
	iconUrl: string;
	designs: Array<ShowDesign>;
};

enum productType {
	Account = 1,
	Design = 2,
}

export const Products = () => {
	const { accounts, setAccounts, designs, setDesigns } = useProducts();
	const { categories } = useCategories();
	const [filteredAccounts, setFilteredAccounts] = useState(Array<FilteredAccountsCategory>);
	const [filteredDesigns, setFilteredDesigns] = useState(Array<FilteredDesignsCategory>);

	useEffect(() => {
		AccountService.getAllAccountsAccountAllGet().then((response) => {
			setAccounts(response);
		});
	}, [setAccounts]);

	useEffect(() => {
		DesignService.getAllDesignsDesignAllGet().then((response) => {
			setDesigns(response);
		});
	}, [setDesigns]);

	useEffect(() => {
		setFilteredAccounts(
			categories
				.sort((a, b) => b.position - a.position)
				.filter((cat) => cat.type_id === 1)
				.map((cat) => ({
					name: cat.name,
					position: cat.position,
					iconUrl: cat.icon_link,
					accounts: accounts.filter((acc) => acc.category_id === cat.id),
				})),
		);
	}, [accounts, categories]);

	useEffect(() => {
		setFilteredDesigns(
			categories
				.sort((a, b) => b.position - a.position)
				.filter((cat) => cat.type_id === 2)
				.map((cat) => ({
					name: cat.name,
					position: cat.position,
					iconUrl: cat.icon_link,
					designs: designs.filter((design) => design.category_id === cat.id),
				})),
		);
	}, [designs, categories]);

	const sumArrays = (arr1: FilteredAccountsCategory[], arr2: FilteredDesignsCategory[]) => {
		const arr = Array<any>();
		return arr.concat(arr1).concat(arr2);
	};

	return (
		<div className={cl.products}>
			{sumArrays(filteredAccounts, filteredDesigns)
				.sort((a, b) => a.position - b.position)
				.map((elem, index) =>
					elem.accounts && elem.accounts.length !== 0 ? (
						<BlockProduct
							title={elem.name}
							key={index}
							listData={elem.accounts}
							iconUrl={elem.iconUrl}
						/>
					) : (
						elem.designs &&
						elem.designs.length !== 0 && (
							<BlockDesignProduct
								title={elem.name}
								key={index}
								listData={elem.designs}
								iconUrl={elem.iconUrl}
							/>
						)
					),
				)}
		</div>
	);
};
