import React, { HTMLAttributes, InputHTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';
import cl from './CustomInput.module.sass';

interface ICustomInput extends InputHTMLAttributes<HTMLInputElement> {
	dollar?: true;
	placeholder?: string;
	white?: boolean;
}

export const CustomInput = (props: ICustomInput) => {
	return (
		<div className={cn({ [cl.wrapInput]: !props.white, [cl.wrapWhite]: props.white })}>
			<input
				className={cl.customInput}
				name={props.name}
				value={props.value}
				onChange={props.onChange}
				placeholder={props.placeholder}
				{...props}
			/>
			{props.dollar ? <span className={cl.dollar}>$</span> : ''}
		</div>
	);
};
