import React, { useEffect } from 'react';
import cl from './LayoutAdmin.module.sass';
import { Outlet } from 'react-router-dom';
import { Header } from '../header';
import { Footer } from '../footer';
import { useIsAbout } from '../../store/isAboutState';
import cn from 'classnames';
import { HeaderAdmin } from '../headerAdmin';
import { OpenAPI } from '../../generated/api/core/OpenAPI';
import { useAuth } from '../../store/authStore';

interface ILayoutAdmin {
	isAbout: boolean;
}

export const LayoutAdmin = (props: ILayoutAdmin) => {
	const trueIsAbout = useIsAbout((state) => state.trueIsAbout);
	const falseIsAbout = useIsAbout((state) => state.falseIsAbout);
	const { setIsAuthorized } = useAuth();

	useEffect(() => {
		const token = localStorage.getItem('token');
		if (token) {
			OpenAPI.TOKEN = token;
			setIsAuthorized(true);
		}
	}, [setIsAuthorized]);

	useEffect(() => {
		if (props.isAbout) {
			trueIsAbout();
		} else {
			falseIsAbout();
		}
	});

	return (
		<div className={cl.app}>
			<HeaderAdmin />
			<div
				className={cn({
					[cl.mainWrapp]: true,
					[cl.mainWrappIsAbout]: props.isAbout,
				})}>
				<Outlet />
			</div>
			<Footer />
		</div>
	);
};
