import React from 'react';
import cl from './BlackPart.module.sass'
import {WrapperForAboutPage} from "../wrapperForAboutPage";
import logo from '../../../assets/LogoForAboutPage.png'
import {IconAbout, IconFast, IconMain, IconMathApp, IconOffer, IconOptionally} from "../../../assets/icons";
import {BlockService} from "./blockService";

const listBlockService = [
    {title: 'Разработка в сжатые сроки', icon: <IconFast/>},
    {title: 'Общетематические приложения', icon: <IconMathApp/>},
    {title: 'Под любые офферы', icon: <IconOffer/>},
    {title: 'Доп один пункт', icon: <IconOptionally/>},
]

export const BlackPart = () => {
    return (
        <div className={cl.blackPart}>
            <WrapperForAboutPage>
                <div className={cl.content}>
                    <div className={cl.logoAndName}>
                        <img src={logo} alt={''}/>
                        <span className={cl.title}>Future Apps – Разработка приложений под ключ</span>
                        <span className={cl.desc}>«Лить гемблу без приложения - все равно, что подмигивать девушке в полной темноте»</span>
                    </div>
                    <div className={cl.blockButton}>
                        <button className={cl.buttonAbout}>
                            <IconAbout/>
                            <span>Больше о нас</span>
                        </button>

                        <button className={cl.buttonOrder}>
                            <IconMain/>
                            <span>Заказать проект</span>
                        </button>
                    </div>

                    <div className={cl.listBlockService}>
                        {
                            listBlockService.map((elem, index) => (
                                <BlockService title={elem.title} icon={elem.icon}/>
                            ))
                        }
                    </div>

                </div>
            </WrapperForAboutPage>
        </div>
    );
};

