import React, { ReactNode } from 'react';
import cl from './NameCategory.module.sass';
import { IconDeleteCRM } from '../../../../assets/icons';
import { CategoryService } from '../../../../generated/api';
import { useCategories } from '../../../../store/categoriesState';

interface INameCategory {
	name: string;
	icon: string;
	id: number;
	onDragStart: () => void;
	onDragEnter: () => void;
	onDragEnd: () => void;
	onDragOver: (e: any) => void;
}

export const NameCategory = ({
	name,
	icon,
	id,
	onDragEnd,
	onDragStart,
	onDragEnter,
	onDragOver,
}: INameCategory) => {
	const { setCategories } = useCategories();
	const deleteCategory = () => {
		const consent = window.confirm('Удалить категорию? Также удалятся все аккаунты в ней!');
		if (consent)
			CategoryService.deleteCategoryCategoryDelete(id).then(() =>
				CategoryService.getAllCategoriesCategoryAllGet().then((response) =>
					setCategories(response),
				),
			);
	};
	return (
		<div
			className={cl.nameCat}
			draggable
			onDragStart={onDragStart}
			onDragEnter={onDragEnter}
			onDragEnd={onDragEnd}
			onDragOver={onDragOver}>
			<div className={cl.beforeButton}>
				<span className={cl.name}>{name}</span>
				<img src={icon} className={cl.icon} alt="Icon" />
				<button onClick={deleteCategory}>
					<IconDeleteCRM />
				</button>
			</div>
		</div>
	);
};
