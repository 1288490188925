import React from 'react';
import cl from './NewTeam.module.sass'
import {BlockTitle} from "../../../components/blockTitle";
import team from '../../../assets/team.png'
import {WrapperForAboutPage} from "../wrapperForAboutPage";
import {IconMain} from "../../../assets/icons";

export const NewTeam = () => {
    return (
        <WrapperForAboutPage>
            <div className={cl.newTeam}>
                <BlockTitle title={'Ваша новая команда'} center/>
                <div className={cl.descWork}>
                    <img src={team} alt={'Фото команды'} className={cl.imgTeam}/>
                    <div className={cl.textContent}>
                        <div className={cl.textContentLeft}>
                            <span className={cl.titleLeft}>Работаем на аутсорс</span>
                            <span className={cl.descLeft}>
                                Мы предоставляем высококвалифицированных разработчиков,
                                которые готовы присоединиться к вашей команде и внести существенный
                                вклад в успех вашего проекта.
                            </span>
                        </div>

                        <button className={cl.buttonPay}>
                            <IconMain/>
                            <span>Заказать проект</span>
                        </button>
                    </div>
                </div>
            </div>
        </WrapperForAboutPage>
    );
};

