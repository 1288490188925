import React, { useState, useEffect } from 'react';
import cl from './Categories.module.sass';
import { Card, ICard } from './card';
import { IconAppStore, IconMain, IconPlayMarket, IconProxy } from '../../../assets/icons';
import { CategoryService } from '../../../generated/api';

const listCat = [
	{ title: 'Самое важное', icon: <IconMain /> },
	{ title: 'Аккаунты Google Play', icon: <IconPlayMarket /> },
	{ title: 'Аккаунты AppStore', icon: <IconAppStore /> },
	{ title: 'Прокси сервевы', icon: <IconProxy /> },
	{ title: 'Готовый дизайн', icon: <IconPlayMarket /> },
	{ title: 'Доп. категория', icon: <IconAppStore /> },
];

export const Categories = () => {
	const [categories, setCategories] = useState(Array<ICard>());

	useEffect(() => {
		CategoryService.getAllCategoriesCategoryAllGet().then((response) =>
			setCategories(
				response.map((x) => ({ title: x.name, icon: x.icon_link, position: x.position })),
			),
		);
	}, []);

	return (
		<div className={cl.categories}>
			{categories
				.sort((a, b) => a.position - b.position)
				.map((elem: ICard, index) => (
					<Card
						position={elem.position}
						title={elem.title}
						icon={elem.icon}
						key={index}
					/>
				))}
		</div>
	);
};
