/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddApp } from '../models/AddApp';
import type { ShowApp } from '../models/ShowApp';
import type { UpdateAppRequest } from '../models/UpdateAppRequest';
import type { UpdateAppResponse } from '../models/UpdateAppResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AppService {
    /**
     * Add App
     * @param requestBody
     * @returns ShowApp Successful Response
     * @throws ApiError
     */
    public static addAppAppPost(
        requestBody: AddApp,
    ): CancelablePromise<ShowApp> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/app',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get App By Id
     * @param appId
     * @returns ShowApp Successful Response
     * @throws ApiError
     */
    public static getAppByIdAppGet(
        appId: string,
    ): CancelablePromise<ShowApp> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/app',
            query: {
                'app_id': appId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete App By Id
     * @param appId
     * @returns UpdateAppResponse Successful Response
     * @throws ApiError
     */
    public static deleteAppByIdAppDelete(
        appId: string,
    ): CancelablePromise<UpdateAppResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/app',
            query: {
                'app_id': appId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update App By Id
     * @param appId
     * @param requestBody
     * @returns UpdateAppResponse Successful Response
     * @throws ApiError
     */
    public static updateAppByIdAppPatch(
        appId: string,
        requestBody: UpdateAppRequest,
    ): CancelablePromise<UpdateAppResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/app',
            query: {
                'app_id': appId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Apps
     * @returns ShowApp Successful Response
     * @throws ApiError
     */
    public static getAllAppsAppAllGet(): CancelablePromise<Array<ShowApp>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/app/all',
        });
    }
}
