import React from 'react';
import cl from './AboutCompany.module.sass';
import { IconMain } from '../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import aboutimg from './img_1.png';

export const AboutCompany = () => {
	const nav = useNavigate();
	function handleClickNav() {
		nav('/about');
	}

	return (
		<div className={cl.aboutCompany}>
			<img src={aboutimg} className={cl.imgCompany} alt="Company" />
			<div className={cl.leftSide}>
				<span className={cl.title}>О компании</span>
				<span className={cl.desc}>Как мы разрабатываем софт, который вы покупаете?</span>
			</div>
			<button className={cl.navButton} onClick={handleClickNav}>
				<IconMain />
				<span className={cl.textButton}>Открыть</span>
			</button>
		</div>
	);
};
