import React from 'react';
import cl from './Portfolio.module.sass';
import { WrapperForAboutPage } from '../wrapperForAboutPage';
import { BlockTitle } from '../../../components/blockTitle';
import { CardApp } from '../../../components/cardApp';
import imgApp from '../../../assets/imgApp.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css';
// import 'swiper/css/pagination'

const listCardsApp = [
	{
		category: 'Слоты',
		titleApp: 'Luna Vegas Slots - Casino Game',
		imgApp: imgApp,
		descCard:
			'Захватывающее приложение для любителей азартных игр и слот-машин. ' +
			'Устройтесь поудобнее и погрузитесь в мир волнительных спинов и больших выигрышей с SlotMania.',
		price: '799',
	},
	{
		category: 'Слоты',
		titleApp: 'Luna Vegas Slots - Casino Game',
		imgApp: imgApp,
		descCard:
			'Захватывающее приложение для любителей азартных игр и слот-машин. ' +
			'Устройтесь поудобнее и погрузитесь в мир волнительных спинов и больших выигрышей с SlotMania.',
		price: '799',
	},
	{
		category: 'Слоты',
		titleApp: 'Luna Vegas Slots - Casino Game',
		imgApp: imgApp,
		descCard:
			'Захватывающее приложение для любителей азартных игр и слот-машин. ' +
			'Устройтесь поудобнее и погрузитесь в мир волнительных спинов и больших выигрышей с SlotMania.',
		price: '799',
	},
	{
		category: 'Слоты',
		titleApp: 'Luna Vegas Slots - Casino Game',
		imgApp: imgApp,
		descCard:
			'Захватывающее приложение для любителей азартных игр и слот-машин. ' +
			'Устройтесь поудобнее и погрузитесь в мир волнительных спинов и больших выигрышей с SlotMania.',
		price: '799',
	},
];

export const Portfolio = () => {
	return (
		<WrapperForAboutPage>
			<div className={cl.portfolio}>
				<BlockTitle title={'Минипортфолио'} center />

				<Swiper
					slidesPerView={'auto'} //если понадобится, чтобы следующий слайд был виден,
					// то нужно переопределить класс .swiper-slide на width: 95%
					spaceBetween={10}
					navigation
					pagination={{
						clickable: true,
					}}
					modules={[Navigation, Pagination, Autoplay]}
					className={cl.wrap}
					autoplay={{
						delay: 4000,
						disableOnInteraction: false,
					}}>
					{listCardsApp.map((elem, index) => (
						<SwiperSlide>
							<CardApp
								category={elem.category}
								titleApp={elem.titleApp}
								imgApp={elem.imgApp}
								descCard={elem.descCard}
								price={elem.price}
								sliderCard
							/>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</WrapperForAboutPage>
	);
};
