import React, { useCallback, useEffect, useState } from 'react';
import { WrapMainContent } from '../../../components/wrapMainContent';
import cl from './Accounts.module.sass';
import iconCard from '../../../assets/appStore.png';
import { CardProduct } from '../../../components/cardProduct';
import { ButtonAddNewProduct } from '../buttonAddNewProduct';
import { EditCardProduct } from '../../../components/editCardProduct';
import Modal from 'react-modal';
import { Configurator, ConfiguratorState } from '../../../components/configurator';
import {
	AccountService,
	CategoryService,
	DesignService,
	ShowAccount,
	ShowDesign,
} from '../../../generated/api';
import { EditDesignCard } from '../../../components/editDesignCard';
import { useProducts } from '../../../store/productsState';
import { Type } from 'typescript';

const listProduct = [
	{
		img: iconCard,
		nameApp: 'Appstorе Console Турция',
		countApp: '3',
		geo: 'Испания',
		antiDetect: 'AdsPower',
	},
];

type Props = {
	title: string;
	categoryType: number;
	categoryId: number;
};

export const CategoryWithItems = ({ title, categoryType, categoryId }: Props) => {
	const [page, setPage] = useState(0);
	const [openModal, setOpenModal] = useState(false);
	const [status, setStatus] = useState<ConfiguratorState>(ConfiguratorState.New);
	const [editableProduct, setEditableProduct] = useState<ShowAccount | ShowDesign>();
	const [products, setProducts] = useState<Array<ShowAccount> | Array<ShowDesign>>([]);

	useEffect(() => {
		categoryType === 1
			? AccountService.getAccountsByCategoryAccountByCategoryGet(categoryId).then(
					(response) => setProducts(response!),
			  )
			: DesignService.getDesignsByCategoryDesignByCategoryGet(categoryId).then((response) =>
					setProducts(response),
			  );
	}, [categoryId, categoryType]);

	const confirmDeleteAccount = useCallback(
		(elem: ShowAccount) => {
			const confirm = window.confirm('Удалить аккаунт? Его нельзя будет восстановить!');
			if (confirm) {
				AccountService.deleteAccountByIdAccountDelete(elem.id)
					.then(() =>
						AccountService.getAccountsByCategoryAccountByCategoryGet(categoryId).then(
							(response) => setProducts(response!),
						),
					)
					.catch((err) => alert('Не удалось удалить аккаунт!'));
			}
		},
		[categoryId],
	);

	const confirmDeleteDesign = useCallback(
		(elem: ShowDesign) => {
			const confirm = window.confirm('Удалить дизайн? Его нельзя будет восстановить!');
			if (confirm) {
				DesignService.deleteDesignDesignDelete(elem.id)
					.then(() =>
						DesignService.getDesignsByCategoryDesignByCategoryGet(categoryId).then(
							(response) => setProducts(response!),
						),
					)
					.catch((err) => alert('Не удалось удалить дизайн!'));
			}
		},
		[categoryId],
	);

	return (
		<WrapMainContent
			title={title}
			handleLeftPaginate={() => page !== 0 && setPage((current) => current - 1)}
			handleRightPaginate={() =>
				page !== Math.ceil((products.length + 1) / 4) - 1 &&
				setPage((current) => current + 1)
			}>
			<div className={cl.blockProduct}>
				{products.slice(page * 4, (page + 1) * 4).map((elem) =>
					categoryType === 1 ? (
						<EditCardProduct
							img={elem.icon_link}
							nameApp={elem.name}
							price={elem.price}
							description={elem.description}
							editClick={() => {
								setEditableProduct(elem);
								setStatus(ConfiguratorState.Edit);
								setOpenModal(true);
							}}
							deleteClick={() => confirmDeleteAccount(elem)}
						/>
					) : (
						<EditDesignCard
							img={elem.icon_link}
							nameDesign={elem.name}
							description={elem.description}
							key={elem.id}
							price={elem.price}
							editClick={() => {
								setEditableProduct(elem);
								setStatus(ConfiguratorState.Edit);
								setOpenModal(true);
							}}
							deleteClick={() => confirmDeleteDesign(elem)}
						/>
					),
				)}
				{page === Math.ceil((products.length + 1) / 4) - 1 && (
					<ButtonAddNewProduct
						openModal={() => {
							setStatus(ConfiguratorState.New);
							setOpenModal(true);
						}}
						size={categoryType === 2 ? 'big' : 'small'}
					/>
				)}
				<Modal
					isOpen={openModal}
					overlayClassName={cl.overlay}
					className={cl.content}
					onRequestClose={() => setOpenModal(false)}>
					<Configurator
						category={{
							id: categoryId,
							name: title,
							type_id: categoryType,
							position: 0,
							icon_link: '',
						}}
						state={status}
						product={status === ConfiguratorState.Edit ? editableProduct : undefined}
						onSuccess={() => {
							setOpenModal(false);
							categoryType === 1
								? AccountService.getAccountsByCategoryAccountByCategoryGet(
										categoryId,
								  ).then((response) => setProducts(response!))
								: DesignService.getDesignsByCategoryDesignByCategoryGet(
										categoryId,
								  ).then((response) => setProducts(response));
						}}
					/>
				</Modal>
			</div>
		</WrapMainContent>
	);
};
