import React from 'react';
import classNames from "classnames";
import cl from "./EditCardApp.module.sass";
import {Platform} from "../platform";
import {IconAppStore, IconMain, IconPlayMarket, IconWeb} from "../../assets/icons";

export interface IEditCardApp {
    category: string
    titleApp: string
    imgApp: string
    descCard: string
    price: string
    sliderCard?: true
}

const listPlatform = [
    {icon: <IconAppStore/>, namePlatform: 'iOS'},
    {icon: <IconPlayMarket/>, namePlatform: 'Google Play'},
    {icon: <IconWeb/>, namePlatform: 'Web'},
]


export const EditCardApp = (props: IEditCardApp) => {
    return (
        <div className={ classNames({
            [cl.cardApp]: true,
            [cl.cardAppSlider]: props.sliderCard
        })}>
            <div className={classNames({
                [cl.leftSide]: true,
                [cl.leftSideSlider]: props.sliderCard
            })}>
                <div className={cl.wrappTop}>
                    <div className={cl.topText}>
                        <span className={cl.category}>{props.category}</span>
                        <span className={cl.titleApp}>{props.titleApp}</span>
                    </div>

                    <img src={props.imgApp}
                         alt={''}
                         className={classNames({
                             [cl.imgMobile]: true,
                             [cl.imgMobileSlider]: props.sliderCard,
                         })}
                    />

                    <div className={cl.listPlatform}>
                        {
                            listPlatform.map((elem, index) => (
                                <Platform icon={elem.icon} namePlatform={elem.namePlatform} key={index}/>
                            ))
                        }
                    </div>
                    <span className={cl.descCard}>
                        {props.descCard}
                    </span>
                </div>
                {
                    props.sliderCard
                        ?
                        <button
                            className={classNames({
                                [cl.buttonPay]: true,
                                [cl.buttonPaySlider]: props.sliderCard,
                            })}
                        >
                            <IconMain/>
                            <span>Заказать проект</span>
                        </button>
                        :
                        <button className={cl.buttonPay}>
                            <IconMain/>
                            <span>Заказать от {props.price}$</span>
                        </button>

                }

            </div>
            <img
                src={props.imgApp}
                alt={''}
                className={classNames({
                    [cl.imgApp]: true,
                    [cl.imgAppSlider]: props.sliderCard
                })}/>
        </div>
    );
};

