import React, { useCallback, useEffect, useState } from 'react';
import { MainWrapperForPage } from '../../components/mainWrapperForPage';
import cl from './AuthAdmin.module.sass';
import cn from 'classnames';
import { Form, Formik } from 'formik';
import { CustomInput } from '../../components/customInput';
import { useNavigate } from 'react-router-dom';
import { AdminLoginService, LoginService, OpenAPI } from '../../generated/api';
import { useAuth } from '../../store/authStore';

const initValues = {
	login: '',
	psw: '',
};

export const AuthAdmin = () => {
	const navigate = useNavigate();
	const { isAuthorized, setIsAuthorized } = useAuth();
	const [error, setError] = useState('');

	const handleLogin = useCallback(
		(values: typeof initValues) => {
			AdminLoginService.loginForAccessTokenAdminLoginTokenPost({
				username: values.login,
				password: values.psw,
			})
				.then((response) => {
					setIsAuthorized(true);
					OpenAPI.TOKEN = response.access_token;
					localStorage.setItem('token', response.access_token);
					navigate('/adminStore');
				})
				.catch(() => setError('Ошибка входа'));
		},
		[navigate, setIsAuthorized],
	);

	useEffect(() => {
		const token = localStorage.getItem('token');
		if (token) {
			OpenAPI.TOKEN = token;
			LoginService.checkLoginTokenLoginCheckTokenPost({
				access_token: token,
				token_type: 'bearer',
			})
				.then(() => {
					setIsAuthorized(true);
				})
				.catch((err) => {
					setIsAuthorized(false);
					navigate('/admin');
				});
		}
	}, [setIsAuthorized, navigate]);

	useEffect(() => {
		if (isAuthorized) navigate('/adminStore');
	}, [isAuthorized, navigate]);

	return (
		<MainWrapperForPage>
			<Formik
				onSubmit={(values, formikHelpers) => {
					handleLogin(values);
					formikHelpers.resetForm();
				}}
				initialValues={initValues}
				validateOnChange={true}
				validate={(values) => {
					const errors: Partial<typeof values> = {};
					if (!values.login) {
						errors.login = 'Обязательное поле';
					}
					if (!values.psw) {
						errors.psw = 'Обязательное поле';
					}
					return errors;
				}}>
				{({ values, errors, isValid, handleChange }) => (
					<div className={cl.wrapForm}>
						<Form className={cl.auth}>
							<span className={cl.title}>Авторизация</span>
							<div className={cl.inputWrap}>
								<CustomInput
									name={'login'}
									value={values.login}
									onChange={handleChange}
									placeholder={'Логин'}
								/>
								{errors.login && <div className={cl.error}>{errors.login}</div>}
							</div>
							<div className={cl.inputWrap}>
								<CustomInput
									name={'psw'}
									value={values.psw}
									type="password"
									onChange={handleChange}
									placeholder={'Пароль'}
								/>
								{errors.psw && <div className={cl.error}>{errors.psw}</div>}
								{error && <div className={cl.error}>{error}</div>}
							</div>
							<button
								className={cn({
									[cl.buttonAuth]: true,
									[cl.buttonAuthFaded]: !isValid,
								})}
								disabled={!isValid}
								type={'submit'}>
								Войти
							</button>
						</Form>
					</div>
				)}
			</Formik>
		</MainWrapperForPage>
	);
};
