import { create } from 'zustand';

interface IAuthState {
	isAuthorized: boolean;
	setIsAuthorized: (isAuthorized: boolean) => void;
}

export const useAuth = create<IAuthState>()((set) => ({
	isAuthorized: false,
	setIsAuthorized: (isAuthorized: boolean) => set(() => ({ isAuthorized })),
}));
