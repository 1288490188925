import React from 'react';
import { WrapperForAboutPage } from '../wrapperForAboutPage';
import cl from './OurProducts.module.sass';
import { BlockTitle } from '../../../components/blockTitle';
import appStore from '../../../assets/appStore.png';
import { CardProduct } from '../../../components/cardProduct';

const listProduct = [
	{
		img: appStore,
		nameApp: 'Аккаунт разработчика Apple Турция',
		countApp: '3',
		geo: 'Испания',
		antiDetect: 'AdsPower',
	},
	{
		img: appStore,
		nameApp: 'Аккаунт разработчика Apple Турция',
		countApp: '3',
		geo: 'Испания',
		antiDetect: 'AdsPower',
	},
	{
		img: appStore,
		nameApp: 'Аккаунт разработчика Apple Турция',
		countApp: '3',
		geo: 'Испания',
		antiDetect: 'AdsPower',
	},
	{
		img: appStore,
		nameApp: 'Аккаунт разработчика Apple Турция',
		countApp: '3',
		geo: 'Испания',
		antiDetect: 'AdsPower',
	},
];

export const OurProducts = () => {
	return (
		<WrapperForAboutPage>
			<div className={cl.ourProducts}>
				<BlockTitle title={'Какие расходники мы предоставляем?'} center />
				<div className={cl.listProducts}>
					{listProduct.map((elem, index) => (
						<CardProduct
							id={''}
							img={elem.img}
							nameApp={elem.nameApp}
							price={'100'}
							description="test"
						/>
					))}
				</div>
			</div>
		</WrapperForAboutPage>
	);
};
