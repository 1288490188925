import React from 'react';
import cl from './LogoBlock.module.sass'
import logo from '../../../assets/Logo.png'
import {useIsAbout} from "../../../store/isAboutState";
import cn from 'classnames'

interface ILogoBlock {
    isFooter?: true
}
export const LogoBlock = (props: ILogoBlock) => {
    const isAbout = useIsAbout(state => state.isAbout)

    return (
        <div className={cl.logoBlock}>
            <img src={logo} alt="Логотип"/>
            <span className={cn({
                [cl.nameStore]: true,
                [cl.whiteNameStore]: isAbout,
                [cl.footerLogo]: props.isFooter,
            }) }>Future Apps</span>
        </div>
    );
};

