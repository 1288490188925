import React from 'react';
import cl from './BlockDesignProduct.module.sass';
import { BlockTitle } from '../../../../components/blockTitle';
import { DesignCard } from '../../../../components/designCard';
import { ShowDesign } from '../../../../generated/api';

interface IBlockDesignProduct {
	title: string;
	iconUrl: string;
	listData: Array<ShowDesign>;
}

export const BlockDesignProduct = (props: IBlockDesignProduct) => {
	return (
		<div className={cl.blockDesignProduct}>
			<BlockTitle title={props.title} iconUrl={props.iconUrl} />
			<div className={cl.listCards}>
				{props.listData.map((elem, index) => (
					<DesignCard
						id={elem.id}
						price={elem.price}
						img={elem.icon_link}
						nameDesign={elem.name}
						description={elem.description}
						key={index}
					/>
				))}
			</div>
		</div>
	);
};
