import {create} from "zustand"

interface IAboutState {
    isAbout: boolean
    trueIsAbout: () => void
    falseIsAbout: () => void
}

export const useIsAbout = create<IAboutState>()((set) => ({
    isAbout: false,
    trueIsAbout: () => set(() => ({isAbout: true})),
    falseIsAbout: () => set(() => ({isAbout: false})),
}))