import React, { useEffect, useRef, useState } from 'react';
import cl from './Category.module.sass';
import {
	AddSvgCRM,
	ClipSvg,
	HuaweiSvgCRM,
	IconAppStoreCRM,
	IconDesignCRM,
	IconMainCRM,
	IconPlayMarketCRM,
	PencilSvg,
	RuStoreSvgCrm,
	TrashSvg,
	XiaomiGetAppsSvgCRM,
} from '../../../assets/icons';
import { NameCategory } from './nameCategory';
import { Form, Formik } from 'formik';
import { CustomInput } from '../../../components/customInput';
import { CustomSelect } from '../../../components/customSelect';
import { useCategories } from '../../../store/categoriesState';
import { ApiError, CategoryService } from '../../../generated/api';

import buttonFile from '../../../assets/buttonFile.png';
import { useAuth } from '../../../store/authStore';

const listCat = [
	{ id: 1, name: 'Самое важное', icon: <IconMainCRM /> },
	{ id: 2, name: 'AppStorе Console ', icon: <IconAppStoreCRM /> },
	{ id: 3, name: 'Google Play Console', icon: <IconPlayMarketCRM /> },
	{ id: 4, name: 'Готовый дизайн', icon: <IconDesignCRM /> },
	{ id: 5, name: 'Xiaomi GetApps Concole', icon: <XiaomiGetAppsSvgCRM /> },
	{ id: 6, name: 'RuStore Console', icon: <RuStoreSvgCrm /> },
	{ id: 7, name: 'Huawei AppGallery Console', icon: <HuaweiSvgCRM /> },
];

const initValue = {
	nameCat: '',
	type: '',
	icon: '',
};

const listCategories = [
	{ id: 1, option: 'Аккаунт' },
	{ id: 2, option: 'Готовый дизайн' },
];

export const Category = () => {
	const { categories, setCategories } = useCategories();
	const { setIsAuthorized } = useAuth();
	const [isModified, setIsModified] = useState(false);

	const dragCategory = useRef<number>(0);
	const draggedOverCategory = useRef<number>(0);

	const handleResort = () => {
		setIsModified(true);
		const clone = [...categories];
		const temp = clone[dragCategory.current];
		clone[dragCategory.current] = clone[draggedOverCategory.current];
		clone[draggedOverCategory.current] = temp;
		setCategories(clone);
	};

	const handleSubmit = (values: typeof initValue) => {
		const type = listCategories.find((cat) => cat.option === values.type)?.id;
		CategoryService.addCategoryCategoryPost({
			name: values.nameCat,
			type_id: type ?? 1,
			icon_b64: iconFileSelected,
			position: categories[categories.length - 1].position + 1,
		})
			.then(() => {
				CategoryService.getAllCategoriesCategoryAllGet().then((response) =>
					setCategories(response),
				);
			})
			.catch((err: ApiError) => {
				if (err.status === 401) {
					localStorage.removeItem('token');
					setIsAuthorized(false);
				} else {
					alert('Ошибка при добавлении!');
				}
			});
	};

	useEffect(() => {
		setCategories(categories.sort((a, b) => b.position - a.position));
	}, []);

	const iconFile = useRef<HTMLInputElement>(null);
	const [iconFileSelected, setIconFileSelected] = useState('');

	const [iconFileName, setIconFileName] = useState('');

	function handlePickIcon() {
		iconFile.current?.click();
	}

	function handleIconFile(event: any) {
		var reader = new FileReader();
		var baseString;
		reader.onloadend = function () {
			baseString = reader.result;
			setIconFileSelected((baseString as string).split(',')[1]);
		};
		if (event.target.files && event.target.files[0]) {
			reader.readAsDataURL(event.target.files[0]);
			setIconFileName(event.target.files[0].name);
		}
	}

	const sendResortedCategories = () => {
		CategoryService.updateCategoriesPositionsCategoryPositionsPatch({
			positions: categories.map((cat, i) => {
				return { category_id: cat.id, position: i };
			}),
		})
			.then(() => setIsModified(false))
			.catch(() => alert('Ошибка при обновлении списка!'));
	};

	const clearIcon = () => {
		setIconFileName('');
		setIconFileSelected('');
	};

	return (
		<div className={cl.category}>
			<div className={cl.currentCat}>
				<span className={cl.titleCur}>Текущие категории:</span>
				<div className={cl.listCat}>
					{categories.map((elem) => (
						<NameCategory
							name={elem.name}
							id={elem.id}
							icon={elem.icon_link}
							key={elem.id}
							onDragStart={() =>
								(dragCategory.current = categories.findIndex(
									(x) => x.id === elem.id,
								))
							}
							onDragEnter={() =>
								(draggedOverCategory.current = categories.findIndex(
									(x) => x.id === elem.id,
								))
							}
							onDragEnd={handleResort}
							onDragOver={(e) => e.preventDefault()}
						/>
					))}
				</div>
				{isModified && (
					<button className={cl.addCut} onClick={sendResortedCategories}>
						Изменить порядок
					</button>
				)}
			</div>

			<Formik
				initialValues={initValue}
				onSubmit={(values, formikHelpers) => {
					handleSubmit(values);
					formikHelpers.setFieldValue('nameCat', '');
					setIconFileSelected('');
					setIconFileName('');
				}}>
				{({ values, handleChange }) => (
					<Form className={cl.formAddCut}>
						<CustomInput
							name={'nameCat'}
							onChange={handleChange}
							placeholder={'Введите новую категорию'}
							white={true}
							value={values.nameCat}
						/>
						<CustomSelect
							name={'type'}
							options={listCategories.map((x) => x.option)}
							onChange={handleChange}
							value={values.type}
						/>
						<button type="button" onClick={!iconFileName ? handlePickIcon : () => {}}>
							<div className={cl.buttonAdd}>
								<ClipSvg />
								{iconFileName ? (
									<span className={cl.buttonAddText}>
										{iconFileName.length > 20
											? iconFileName.substring(iconFileName.length - 20)
											: iconFileName}
									</span>
								) : (
									<span className={cl.buttonAddText}>Прикрепить файл</span>
								)}
								{iconFileName && (
									<button type="button" onClick={handlePickIcon}>
										<PencilSvg />
									</button>
								)}
								{iconFileName && (
									<button type="button" onClick={clearIcon}>
										<TrashSvg />
									</button>
								)}
							</div>
						</button>
						<input
							type={'file'}
							onChange={handleIconFile}
							accept={'.svg'}
							className={cl.hiddenChoseFile}
							ref={iconFile}
						/>
						<button type="submit" className={cl.addCut}>
							Добавить категорию
						</button>
					</Form>
				)}
			</Formik>
		</div>
	);
};
