import React, {ReactNode} from 'react';
import cl from './WrapperForAboutPage.module.sass'

interface IWrapperForAboutPage {
    children: ReactNode
}
export const WrapperForAboutPage = (props: IWrapperForAboutPage) => {
    return (
        <div className={cl.wrapperForAboutPage}>
            {props.children}
        </div>
    );
};

