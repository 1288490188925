import React, { ReactNode } from 'react';
import cl from './Card.module.sass';

export interface ICard {
	title: string;
	icon: string;
	position: number;
}

export const Card = (props: ICard) => {
	const ifFirstCard = props.title === 'Самое важное' ? cl.firstCard : cl.card;

	return (
		<a href={`#${props.title}`} className={ifFirstCard}>
			<span className={cl.title}>{props.title}</span>
			<img className={cl.icon} src={props.icon} alt={props.title} />
		</a>
	);
};
