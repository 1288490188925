import React, { useCallback, useState } from 'react';
import cl from './Card.module.sass';
import cn from 'classnames';
import iconCard from '../../assets/playMarket.png';
import { Minus, Plus } from '../../assets/icons';
import { DescText } from './descText';
import Modal from 'react-modal';
import { OrderButton } from '../../pages/store/products/orderButton';
import { Form, Formik } from 'formik';
import { BlockInput } from '../blockInput';
import { OrderService } from '../../generated/api';
import { ParseBackendText } from '../../utils/parse-backend-text';

export interface ICardProduct {
	id: string;
	img: string;
	nameApp: string;
	description: string;
	price: string;
}

export const CardProduct = (props: ICardProduct) => {
	const [openModal, setOpenModal] = useState(false);
	const [isInfoCard, setInfoCard] = useState(false);
	const [orderStatus, setOrderStatus] = useState<'none' | 'creating' | 'pending'>('none');

	const initValues = {
		email: '',
	};

	const parseDescription = useCallback(
		(description: string) => {
			const lexemes = ParseBackendText(description);
			return lexemes.map((x, i) => {
				if (x.type === 'normal') {
					return <span className={cl.normal}>{x.value}</span>;
				}
				if (x.type === 'bold') {
					return <span className={cl.bold}>{x.value}</span>;
				}
				if (x.type === 'link') {
					if (x.value.includes('|')) {
						return (
							<a
								className={cl.link}
								href={x.value.split('|')[0]}
								rel="noreferrer"
								target="_blank">
								{x.value.split('|')[1]}
							</a>
						);
					}
					if (x.value === 'INFO') {
						if (!isInfoCard) setInfoCard(true);
						return '';
					}
				}
				if (x.type === 'line-break') {
					return <br />;
				}
				return '';
			});
		},
		[isInfoCard],
	);

	return (
		<div className={cl.cardProduct}>
			<div className={cl.topBlock}>
				<img src={props.img} alt={'Иконка'} className={cl.imgCard} />
				{/*<div className={cl.countProduct}>
					<button onClick={minus}>
						<Minus />
					</button>
					<div className={cl.countNumber}>
						<span>{countNumber}</span>
					</div>
					<button onClick={plus}>
						<Plus />
					</button>
    </div> */}
				<span className={cl.nameApp}>{props.nameApp}</span>
			</div>
			<div className={cl.middleBlock}>
				<div className={cl.descList}>{parseDescription(props.description)}</div>
			</div>
			<OrderButton disabled={isInfoCard} onClick={() => setOpenModal(true)}>
				Купить ({props.price}$)
			</OrderButton>
			<Modal
				isOpen={openModal}
				overlayClassName={cl.overlay}
				className={cl.content}
				onRequestClose={() => setOpenModal(false)}>
				<div className={cl.wrapper}>
					<div className={cl.wrapperInner}>
						<Formik
							initialValues={initValues}
							validateOnChange={true}
							validate={(values) => {
								const errors: Partial<typeof values> = {};
								if (!values.email) {
									errors.email = 'Обязательное поле';
								} else if (
									!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
								) {
									errors.email = 'Неверный Email';
								}
								return errors;
							}}
							onSubmit={(values, formikHelpers) => {
								OrderService.createOrderOrderPost({
									client_email: values.email,
									product_id: props.id,
								})
									.then((response) => {
										setOrderStatus('pending');
										window.location.href = response.payment_link;
									})
									.catch((error) => {
										setOrderStatus('none');
										alert('Произошла ошибка при создании заказа!');
									});
								setOrderStatus('creating');
							}}>
							{({ values, isValid, errors, touched, handleChange }) => (
								<>
									{orderStatus === 'none' && (
										<Form className={cl.formGroup}>
											<BlockInput
												title={'Введите свой email'}
												name={'email'}
												value={values.email}
												white={true}
												onChange={handleChange}
												placeholder={'example@example.com'}
											/>
											{errors.email && (
												<div className={cl.errors}>{errors.email}</div>
											)}
											<button
												type="submit"
												disabled={!isValid}
												className={cn({
													[cl.buy]: true,
													[cl.buyFaded]: !isValid,
												})}>
												Приобрести товар
											</button>
										</Form>
									)}
									{orderStatus === 'creating' && (
										<span>Создание заказа в процессе...</span>
									)}
									{orderStatus === 'pending' && (
										<span>
											Заказ создан, сейчас вы будете перенаправлены на сайт
											для оплаты.
										</span>
									)}
								</>
							)}
						</Formik>
					</div>
				</div>
			</Modal>
		</div>
	);
};
