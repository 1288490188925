import React, { useCallback, useEffect, useState } from 'react';
import cl from './Store.module.sass';
import { TitleBlock } from '../../components/titleBlock';
import { Categories } from './categories';
import { CustomHR } from '../../components/customHR';
import { AboutCompany } from './aboutCompany';
import { Products } from './products';
import { MainWrapperForPage } from '../../components/mainWrapperForPage';
import { useCategories } from '../../store/categoriesState';
import {
	CategoryService,
	OrderService,
	PaidOrderAccount,
	PaidOrderDesign,
} from '../../generated/api';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';

export const Store = () => {
	const { id: orderId } = useParams();
	const { setCategories } = useCategories();
	const [orderStatus, setOrderStatus] = useState('none');
	const [designLink, setDesignLink] = useState('');
	const [file, setFile] = useState('');
	const [fileName, setFileName] = useState('');

	const [openModal, setOpenModal] = useState(false);

	const citate =
		'«Не иметь в запасе аккаунтов Google Play Console - все равно, что ждать, пока твоя пачка сигарет кончится, чтобы купить новую»';

	useEffect(() => {
		CategoryService.getAllCategoriesCategoryAllGet().then((response) =>
			setCategories(response),
		);
	}, [setCategories]);

	const fetchStatus = useCallback(() => {
		if (orderId) {
			OrderService.getOrderOrderGet(orderId)
				.then((response) => {
					setOrderStatus(response.status);
					if (response.status === 'completed') {
						if (response.hasOwnProperty('account_file'))
							setFile((response as PaidOrderAccount).account_file);
						setFileName((response as PaidOrderAccount).account_file_name);
						if (response.hasOwnProperty('design_link'))
							setDesignLink((response as PaidOrderDesign).design_link);
					}
				})
				.catch((err) => {
					setOrderStatus('invalid');
				})
				.finally(() => setOpenModal(true));
		}
	}, [orderId]);

	const downloadFile = useCallback(() => {
		const blob = new Blob([file], { type: 'text/plain' });
		const link = document.createElement('a');
		link.setAttribute('href', URL.createObjectURL(blob));
		link.setAttribute('download', fileName);
		link.click();
	}, [file, fileName]);

	useEffect(() => fetchStatus(), [fetchStatus, orderId]);

	return (
		<MainWrapperForPage>
			<TitleBlock
				boldTitle={'Магазин софта'}
				desc={
					'Все необходимое для работы с трафиком, сетью и раскруткой аккаунтов в одном месте.'
				}
				rightSide={''}
			/>
			<Categories />
			<div className={cl.blockHR}>
				<CustomHR />
			</div>
			<AboutCompany />
			<Products />
			<Modal
				isOpen={openModal}
				overlayClassName={cl.overlay}
				className={cl.content}
				onRequestClose={() => setOpenModal(false)}>
				<div className={cl.wrapper}>
					<div className={cl.wrapperInner}>
						{orderStatus === 'completed' && (
							<>
								<span>
									Успешно! Данные были высланы на почту. Ниже вы можете скачать
									файл
								</span>
								<button className={cl.buy} onClick={downloadFile}>
									Скачать файл
								</button>
							</>
						)}
						{orderStatus === 'pending' && (
							<>
								<span>
									Мы ещё не можем подтвердить, что заказ выполнен. Подождите
									немного и обновите статус ещё раз.
								</span>
								<button className={cl.buy} onClick={fetchStatus}>
									Обновить статус
								</button>
							</>
						)}
						{orderStatus === 'cancelled' && (
							<>
								<span>Данный заказ был отменён.</span>
								<button className={cl.buy} onClick={() => setOpenModal(false)}>
									Закрыть
								</button>
							</>
						)}

						{orderStatus === 'invalid' && (
							<>
								<span>Заказа с таким номером не существует!</span>
								<button className={cl.buy} onClick={() => setOpenModal(false)}>
									Закрыть
								</button>
							</>
						)}
					</div>
				</div>
			</Modal>
		</MainWrapperForPage>
	);
};
