/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddAccount } from '../models/AddAccount';
import type { ShowAccount } from '../models/ShowAccount';
import type { UpdateAccountRequest } from '../models/UpdateAccountRequest';
import type { UpdateAccountResponse } from '../models/UpdateAccountResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AccountService {
    /**
     * Get Account By Id
     * @param accountId
     * @returns ShowAccount Successful Response
     * @throws ApiError
     */
    public static getAccountByIdAccountGet(
        accountId: string,
    ): CancelablePromise<ShowAccount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/account',
            query: {
                'account_id': accountId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Account
     * @param requestBody
     * @returns ShowAccount Successful Response
     * @throws ApiError
     */
    public static addAccountAccountPost(
        requestBody: AddAccount,
    ): CancelablePromise<ShowAccount> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Account
     * @param accountId
     * @param requestBody
     * @returns UpdateAccountResponse Successful Response
     * @throws ApiError
     */
    public static updateAccountAccountPatch(
        accountId: string,
        requestBody: UpdateAccountRequest,
    ): CancelablePromise<UpdateAccountResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/account',
            query: {
                'account_id': accountId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Account By Id
     * @param accountId
     * @returns UpdateAccountResponse Successful Response
     * @throws ApiError
     */
    public static deleteAccountByIdAccountDelete(
        accountId: string,
    ): CancelablePromise<UpdateAccountResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/account',
            query: {
                'account_id': accountId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Accounts
     * @returns ShowAccount Successful Response
     * @throws ApiError
     */
    public static getAllAccountsAccountAllGet(): CancelablePromise<Array<ShowAccount>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/account/all',
        });
    }
    /**
     * Get Accounts By Category
     * @param categoryId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getAccountsByCategoryAccountByCategoryGet(
        categoryId: number,
    ): CancelablePromise<(Array<ShowAccount> | null)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/account/by_category',
            query: {
                'category_id': categoryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
