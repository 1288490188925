import React, { useRef, useState } from 'react';
import cl from './AccountForm.module.sass';
import { Form, Formik } from 'formik';
import { CustomSelect } from '../../../components/customSelect';
import { BlockInput } from '../../../components/blockInput';
import buttonFile from '../../../assets/buttonFile.png';
import { ButtonAdd } from '../../../components/buttonAdd';
import { AccountService, ApiError, ShowAccount, ShowCategory } from '../../../generated/api';
import { ConfiguratorState } from '../../../components/configurator';
import { useAuth } from '../../../store/authStore';
import { ClipSvg, PencilSvg, TrashSvg } from '../../../assets/icons';

interface IAccountForm {
	category: ShowCategory;
	status: ConfiguratorState;
	onSuccess: () => void;
	product?: ShowAccount;
}

const listCategories = [
	{ id: 1, option: 'Аккаунт Google' },
	{ id: 2, option: 'Аккаунт Apple' },
	{ id: 3, option: 'Готовый дизайн' },
];

export const AccountForm = ({ category, product, status, onSuccess }: IAccountForm) => {
	const initValue = {
		category: category.name,
		name: product?.name ?? '',
		price: product?.price ?? 0,
		desc: product?.description ?? '',
		icon: '',
		file: '',
	};

	const { setIsAuthorized } = useAuth();

	const txtFile = useRef<HTMLInputElement>(null);
	const [txtFileSelected, setTxtFileSelected] = useState('');

	const iconFile = useRef<HTMLInputElement>(null);
	const [iconFileSelected, setIconFileSelected] = useState('');

	const [iconFileName, setIconFileName] = useState('');
	const [txtFileName, setTxtFileName] = useState('');

	function handleIconFile(event: any) {
		var reader = new FileReader();
		var baseString;
		reader.onloadend = function () {
			baseString = reader.result;
			setIconFileSelected((baseString as string).split(',')[1]);
		};
		if (event.target.files && event.target.files[0]) {
			reader.readAsDataURL(event.target.files[0]);
			setIconFileName(event.target.files[0].name);
		}
	}

	function handlePickIcon() {
		iconFile.current?.click();
	}

	function handleTxtFile(event: any) {
		var reader = new FileReader();
		var baseString;
		reader.onloadend = function () {
			baseString = reader.result;
			setTxtFileSelected((baseString as string).split(',')[1]);
		};
		if (event.target.files && event.target.files[0]) {
			reader.readAsDataURL(event.target.files[0]);
			setTxtFileName(event.target.files[0].name);
		}
	}

	function handlePickTxt() {
		txtFile.current?.click();
	}

	const clearIcon = () => {
		setIconFileName('');
		setIconFileSelected('');
	};
	const clearFile = () => {
		setTxtFileName('');
		setTxtFileSelected('');
	};

	return (
		<>
			<Formik
				initialValues={initValue}
				onSubmit={(values, formikHelpers) => {
					values.icon = iconFileSelected;
					values.file = txtFileSelected;

					status === ConfiguratorState.New
						? AccountService.addAccountAccountPost({
								category_id: category.id,
								description: values.desc,
								name: values.name,
								price: values.price,
								file_b64: values.file,
								icon_b64: values.icon,
						  })
								.then(() => onSuccess())
								.catch((err: ApiError) => {
									if (err.status === 401) {
										localStorage.removeItem('token');
										setIsAuthorized(false);
									} else {
										alert('Ошибка при добавлении!');
									}
								})
						: AccountService.updateAccountAccountPatch(product?.id!, {
								category_id: category.id,
								description: values.desc,
								name: values.name,
								price: values.price,
								...(txtFileSelected ? { file_b64: txtFileSelected } : {}),
								...(iconFileSelected ? { icon_b64: iconFileSelected } : {}),
						  })
								.then(() => onSuccess())
								.catch((err: ApiError) => {
									if (err.status === 401) {
										localStorage.removeItem('token');
										setIsAuthorized(false);
									} else {
										alert('Ошибка при обновлении!');
									}
								});
				}}>
				{({ values, handleChange }) => (
					<Form className={cl.formGroup}>
						<BlockInput
							title={'Категория товара'}
							name={'category'}
							value={values.category}
							disabled={true}
							onChange={handleChange}
							placeholder={'Введите информацию'}
						/>
						<BlockInput
							title={'Название'}
							name={'name'}
							value={values.name}
							onChange={handleChange}
							placeholder={'Введите информацию'}
						/>
						<BlockInput
							title={'Описание'}
							name={'desc'}
							value={values.desc}
							onChange={handleChange}
							placeholder={'Введите информацию'}
						/>
						<BlockInput
							title={'Цена'}
							name={'price'}
							type="number"
							value={values.price}
							onChange={handleChange}
							placeholder={'Введите информацию'}
						/>

						<>
							<div className={cl.blockButtonAdd}>
								<div className={cl.blockText}>
									<span className={cl.blockTextTitle}>Изображение</span>
									<span className={cl.title}>
										(Минимум 100x100 px, не больше 1 мб)
									</span>
								</div>
								<button
									type="button"
									onClick={!iconFileName ? handlePickIcon : () => {}}>
									<div className={cl.buttonAdd}>
										<ClipSvg />
										{iconFileName ? (
											<span className={cl.buttonAddText}>
												{iconFileName.length > 20
													? iconFileName.substring(
															iconFileName.length - 20,
													  )
													: iconFileName}
											</span>
										) : (
											<span className={cl.buttonAddText}>
												Прикрепить файл
											</span>
										)}
										{iconFileName && (
											<button type="button" onClick={handlePickIcon}>
												<PencilSvg />
											</button>
										)}
										{iconFileName && (
											<button type="button" onClick={clearIcon}>
												<TrashSvg />
											</button>
										)}
									</div>
								</button>
								<input
									type={'file'}
									onChange={handleIconFile}
									accept={'.png, .svg'}
									className={cl.hiddenChoseFile}
									ref={iconFile}
								/>
							</div>

							<div className={cl.blockButtonAdd}>
								<div className={cl.blockText}>
									<span className={cl.blockTextTitle}>Файл товара</span>
									<span className={cl.title}>
										(Загрузите файл для отправки в .txt)
									</span>
								</div>
								<button
									type="button"
									onClick={!txtFileName ? handlePickTxt : () => {}}>
									<div className={cl.buttonAdd}>
										<ClipSvg />
										{txtFileName ? (
											<span className={cl.buttonAddText}>
												{txtFileName.length > 20
													? txtFileName.substring(txtFileName.length - 20)
													: txtFileName}
											</span>
										) : (
											<span className={cl.buttonAddText}>
												Прикрепить файл
											</span>
										)}
										{txtFileName && (
											<button type="button" onClick={handlePickTxt}>
												<PencilSvg />
											</button>
										)}
										{txtFileName && (
											<button type="button" onClick={clearFile}>
												<TrashSvg />
											</button>
										)}
									</div>
								</button>
								<input
									type={'file'}
									onChange={handleTxtFile}
									accept={'.txt'}
									className={cl.hiddenChoseFile}
									ref={txtFile}
								/>
							</div>
						</>
						<ButtonAdd type={'submit'} state={status} />
					</Form>
				)}
			</Formik>
		</>
	);
};
