import React, { ReactNode } from 'react';
import cl from './OrderButton.module.sass';

interface IOrderButton {
	children: ReactNode;
	disabled?: boolean;
	onClick?: () => void;
}
export const OrderButton = (props: IOrderButton) => {
	return (
		<button className={cl.orderButton} onClick={props.onClick} disabled={props.disabled}>
			{props.children}
		</button>
	);
};
