import React from 'react';
import cl from './Navbar.module.sass'
import {ILinkBlock, LinkBlock} from "./linkBlock";
import {IconAbout, IconApps, IconGaranty, IconStore} from "../../../assets/icons";


const listLinks = [
    {link: '/adminStore', namePage: 'Магазин софта', icon: <IconStore />},
    {link: '/adminApps', namePage: 'Приложения', icon: <IconApps/>},
    {link: '/adminAbout', namePage: 'О нас', icon: <IconAbout/>},
]

export const Navbar = () => {
    return (
        <nav className={cl.nav}>
            {
                listLinks.map((elem: ILinkBlock, index) => (
                    <LinkBlock
                        link={elem.link}
                        namePage={elem.namePage}
                        icon={elem.icon}
                        key={index}
                    />
                ))
            }
        </nav>
    );
};

