import React, { ReactNode, useState } from 'react';
import cl from './WrapMainContent.module.sass';
import { EditCardSvg, LeftPaginateSvg, RightPaginateSvg } from '../../assets/icons';
import { CustomInput } from '../customInput';
import { Form, Formik } from 'formik';

interface WrapMainContent {
	handleLeftPaginate?: () => void;
	handleRightPaginate?: () => void;
	title: string;
	children: ReactNode;
}

export const WrapMainContent = ({
	children,
	title,
	handleLeftPaginate,
	handleRightPaginate,
}: WrapMainContent) => {
	//нужно продумать логику сохранения title после редактрования с Камилем и Шакиром

	const initValue = {
		title: title,
	};

	const [edit, setEdit] = useState(false);

	function handleEdit() {
		setEdit(!edit);
	}

	return (
		<div className={cl.wrapMainContent}>
			<div className={cl.wrap}>
				<div className={cl.wrapEditBlock}>
					{!edit ? (
						<span className={cl.title}>{title}</span>
					) : (
						<Formik initialValues={initValue} onSubmit={() => {}}>
							{({ values, handleChange }) => (
								<Form>
									<CustomInput
										value={values.title}
										onChange={handleChange}
										name={'title'}
									/>
								</Form>
							)}
						</Formik>
					)}
					<div className={cl.buttons}>
						<div className={cl.wrapEdit} onClick={handleEdit}>
							<EditCardSvg />
						</div>
						<div className={cl.wrapEdit} onClick={handleLeftPaginate}>
							<LeftPaginateSvg />
						</div>
						<div className={cl.wrapEdit} onClick={handleRightPaginate}>
							<RightPaginateSvg />
						</div>
					</div>
				</div>

				{children}
			</div>
		</div>
	);
};
