import React from 'react';
import cl from './BlockTitle.module.sass';
import { CustomHR } from '../customHR';
import { IconAccountsAppStore, IconMain } from '../../assets/icons';
import classNames from 'classnames';

export interface IBlockTitle {
	title: string;
	iconUrl?: string;
	center?: true;
}

export const BlockTitle = (props: IBlockTitle) => {
	return (
		<div
			className={classNames({
				[cl.blockTitle]: true,
				[cl.blockTitleCenter]: props.center,
			})}
			id={props.title}>
			<div
				className={classNames({
					[cl.block]: true,
					[cl.blockCenter]: props.center,
				})}>
				{props.title === 'Самое важное' ? <IconMain /> : ''}
				{props.title === 'Аккаунты AppStore' ? <IconAccountsAppStore /> : ''}
				{props.iconUrl && <img src={props.iconUrl} alt="icon" />}
				<span className={cl.text}>{props.title}</span>
			</div>
			<div className={cl.wrapHR}>
				<CustomHR />
			</div>
		</div>
	);
};
