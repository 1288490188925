import React, { InputHTMLAttributes } from 'react';
import cl from './BlockInput.module.sass';
import { CustomInput } from '../customInput';

interface IBlockInput extends InputHTMLAttributes<HTMLInputElement> {
	title: string;
	white?: boolean;
}

export const BlockInput = (props: IBlockInput) => {
	return (
		<div className={cl.blockInput}>
			<span className={cl.title}>{props.title}</span>
			<CustomInput
				name={props.name}
				value={props.value}
				white={props.white}
				placeholder={props.placeholder}
				onChange={props.onChange}
				{...props}
			/>
		</div>
	);
};
