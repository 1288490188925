import { create } from 'zustand';
import { ShowAccount, ShowApp, ShowDesign } from '../generated/api';

interface IProductsState {
	accounts: Array<ShowAccount>;
	designs: Array<ShowDesign>;
	apps: Array<ShowApp>;
	setAccounts: (accounts: Array<ShowAccount>) => void;
	setDesigns: (designs: Array<ShowDesign>) => void;
	setApps: (apps: Array<ShowApp>) => void;
}

export const useProducts = create<IProductsState>()((set) => ({
	accounts: [],
	designs: [],
	apps: [],
	setAccounts: (accounts: Array<ShowAccount>) => set(() => ({ accounts })),
	setDesigns: (designs: Array<ShowDesign>) => set(() => ({ designs })),
	setApps: (apps: Array<ShowApp>) => set(() => ({ apps })),
}));
