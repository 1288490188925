import React from 'react';
import cl from './ButtonAdd.module.sass';
import { ConfiguratorState } from '../configurator';

interface IButtonAdd {
	type: 'submit' | 'reset' | 'button' | undefined;
	state: ConfiguratorState;
}

export const ButtonAdd = ({ type, state }: IButtonAdd) => {
	return (
		<button type={type} className={cl.buttonAdd}>
			{state === ConfiguratorState.New ? 'Добавить товар' : 'Обновить товар'}
		</button>
	);
};
