/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddDesign } from '../models/AddDesign';
import type { ShowDesign } from '../models/ShowDesign';
import type { UpdateDesignRequest } from '../models/UpdateDesignRequest';
import type { UpdateDesignResponse } from '../models/UpdateDesignResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DesignService {
    /**
     * Get All Designs
     * @returns ShowDesign Successful Response
     * @throws ApiError
     */
    public static getAllDesignsDesignAllGet(): CancelablePromise<Array<ShowDesign>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/design/all',
        });
    }
    /**
     * Get Designs By Category
     * @param categoryId
     * @returns ShowDesign Successful Response
     * @throws ApiError
     */
    public static getDesignsByCategoryDesignByCategoryGet(
        categoryId: number,
    ): CancelablePromise<Array<ShowDesign>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/design/by_category',
            query: {
                'category_id': categoryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Design By Id
     * @param designId
     * @returns ShowDesign Successful Response
     * @throws ApiError
     */
    public static getDesignByIdDesignGet(
        designId: string,
    ): CancelablePromise<ShowDesign> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/design',
            query: {
                'design_id': designId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Design
     * @param requestBody
     * @returns ShowDesign Successful Response
     * @throws ApiError
     */
    public static addDesignDesignPost(
        requestBody: AddDesign,
    ): CancelablePromise<ShowDesign> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/design',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Design
     * @param designId
     * @returns UpdateDesignResponse Successful Response
     * @throws ApiError
     */
    public static deleteDesignDesignDelete(
        designId: string,
    ): CancelablePromise<UpdateDesignResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/design',
            query: {
                'design_id': designId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Design
     * @param designId
     * @param requestBody
     * @returns UpdateDesignResponse Successful Response
     * @throws ApiError
     */
    public static updateDesignDesignPatch(
        designId: string,
        requestBody: UpdateDesignRequest,
    ): CancelablePromise<UpdateDesignResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/design',
            query: {
                'design_id': designId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
